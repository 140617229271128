import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getLoansList } from '../../store/actions/loanActions';
import { MODAL_TYPES } from '../../components/ModalProvider';
import { setModals } from '../../store/actions/commonActions';
import AnalyticsGA from '../../analyticsGA';
import Preloader from '../../components/Preloader';
import { LoansTable } from './table';
import { LoansList } from './list';
import { SuspendResidenceWarning } from '../../components/Warnings/SuspendResidenceWarning';
import { getUserProfile } from '../../store/actions/profileActions';
import { USER_STATUSES } from '../../helpers/constants';
import s from './index.module.scss';

const LoansModule = () => {
  const dispatch = useDispatch();
  const { loansList } = useSelector(state => state.loanStore);
  const { innerWidth } = useSelector(state => state.commonStore);
  const profile = useSelector(state => state.profileStore.profile.data);
  const [pageSettings, setPageSettings] = useState({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getLoansList(pageSettings));
    AnalyticsGA.pageNavigation('Loans');
  }, []);

  const getNext = () => {
    const { page } = pageSettings;
    const nextPage = page + 1;

    dispatch(
      getLoansList(
        {
          ...pageSettings,
          page: nextPage,
        },
        true
      )
    );

    setPageSettings(prevState => ({ ...prevState, page: nextPage }));
  };

  const onLoanOpen = loan => {
    dispatch(
      setModals({
        selectedLoan: loan,
        type: MODAL_TYPES.loan_info,
      })
    );
  };

  const { page } = pageSettings;
  const { data, loading } = loansList;
  const hasLoans = data?.total_count > 0;

  if (profile?.status === USER_STATUSES.suspended && !hasLoans) {
    return (
      <div className={s.wrapper}>
        <SuspendResidenceWarning />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      {!hasLoans && !loading && (
        <div className={classnames(s.empty, 'f-16')}>No loans yet.</div>
      )}

      {page === 1 && loading ? (
        <Preloader className={s.preloader} />
      ) : (
        hasLoans && (
          <>
            {profile?.status === USER_STATUSES.suspended && (
              <SuspendResidenceWarning className={s.warning} />
            )}

            {innerWidth < 992 ? (
              <LoansList data={data} onLoanOpen={onLoanOpen} />
            ) : (
              <LoansTable data={data} onLoanOpen={onLoanOpen} />
            )}
          </>
        )
      )}

      {hasLoans && data?.total_pages > page && (
        <div style={{ textAlign: 'center' }}>
          <button
            type="button"
            className={classnames(s.more, 'f-24 f-500')}
            onClick={getNext}
          >
            Load more
          </button>
        </div>
      )}
    </div>
  );
};

export default LoansModule;
