import React from 'react';
import { Tooltip } from '../Tooltip/bottom';
import { MyLink } from '../MyLink';
import { APP_LINKS } from '../../helpers/links';
import { LOAN_GROUPS } from '../../helpers/constants';

export const TERM_TOOLTIPS = {
  earnings: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_earning">
        This is your loan income. It is calculated based on the amount of your
        loan investment.
      </div>
    </Tooltip>
  ),
  net_annual_return: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_return">
        Net annualised return (NAR) measures the actual rate of all your
        investments return since you started investing on Scramble. It includes
        anything that affects your returns, such as delays and bonuses.
      </div>
    </Tooltip>
  ),
  term: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_term">
        The amount of time investors give brands to repay a loan. Please note
        that loan terms can be extended up to 24 months. Learn more about{' '}
        <MyLink href={APP_LINKS.helpLoanTerms()} underline>
          Scramble loan terms
        </MyLink>
        .
      </div>
    </Tooltip>
  ),
  repayments: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_repayments">
        Type of repayments:
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>Monthly</span> – you will receive
        repayments on a monthly basis, starting on the 5th day of the month
        following the round. Your earnings are also accrued monthly, but paid at
        the end of the period.
        <br />
        <br />
        <span style={{ fontWeight: '500' }}>Last month</span> – you will receive
        your earnings and all repayments at the end of the loan term.
      </div>
    </Tooltip>
  ),
  skin: ({ className }) => (
    <Tooltip className={className}>
      <div className="t_skin">
        We invest up to 20% of our own capital in Group A in each round because
        we believe in the amazing potential of the brands we work with. Group B
        is fully open to investors who are looking for a high rate of return but
        are also prepared to take on a high level of risk.
      </div>
    </Tooltip>
  ),
  protection: ({ group, className }) => (
    <Tooltip className={className}>
      <div className="t_protection">
        {group === LOAN_GROUPS.conservative.id && (
          <ul className="scr-circle-list">
            <li>Regular monthly repayments</li>
            <li>Co-founder team guarantees</li>
            <li>First loss capital</li>
          </ul>
        )}

        {group === LOAN_GROUPS.moderate.id && (
          <ul className="scr-circle-list">
            <li>Co-founder team guarantees</li>
          </ul>
        )}
      </div>
    </Tooltip>
  ),
};
