import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import ProgressBar from '../../../components/ProgressBar';
import { prettyDate, prettyFloatMoney } from '../../../helpers/utils';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import s from './index.module.scss';
import { Tooltip } from '../../../kit/Tooltip';
import { InfoIcon } from '../../../components/UI/Icons';

export const LoansTable = ({ data, onLoanOpen }) => (
  <div className={s.wrapper}>
    <div className={s.inner}>
      <div className={s.table}>
        <div className={s.thead}>
          <div className={s.thead_col}>Round</div>
          <div className={s.thead_col}>Origination date</div>
          <div className={s.thead_col}>Loan amount</div>
          <div className={s.thead_col}>Repayment progress, %</div>
          <div className={s.thead_col}>Repaid</div>
          <div className={s.thead_col}>Your earnings</div>
        </div>

        <div className={s.tbody}>
          <div className={s.tbody_row_summary}>
            <div className={s.tbody_col}>
              <p className="f-18 f-500">
                Total:{' '}
                <span>
                  {data.total_count === 1
                    ? `1 round`
                    : `${data.total_count} rounds`}
                </span>
              </p>
            </div>
            <div className={s.tbody_col}>
              <span className="f-18 f-500">—</span>
            </div>
            <div className={classnames(s.tbody_col, 'tl')}>
              <span className="f-18 f-500">
                {prettyFloatMoney(data.total_amount)}
              </span>
            </div>
            <div className={s.tbody_col}>
              <ProgressBar
                value={data.repayment_average}
                text={`avg. ${data ? data.repayment_average : 0}%`}
              />
            </div>
            <div className={classnames(s.tbody_col, 'tl')}>
              <span className="f-18 f-500">
                {prettyFloatMoney(data.total_repayments)}
              </span>
            </div>
            <div className={s.tbody_col}>
              <span className="f-18 f-500">
                + {prettyFloatMoney(data.total_earnings + data.total_accrued)}
              </span>
            </div>
          </div>

          {data.list.map(round => {
            const {
              fundraising_name,
              fundraising_id,
              start_date,
              end_date,
              max_earnings,
              loan_amount,
              repayment_status,
              repaid_amount,
              earnings,
              flat_fee_accrued,
            } = round;

            return (
              <div
                className={classnames(s.tbody_row, 'hov')}
                key={fundraising_name}
                onClick={() => onLoanOpen(round)}
              >
                <div className={s.tbody_col}>
                  <span className="f-16 f-400">{fundraising_name}</span>
                </div>
                <div className={s.tbody_col}>
                  <span className="f-16 f-400">
                    {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
                  </span>
                </div>
                <div className={classnames(s.tbody_col, s.loan)}>
                  <span className="f-16 f-400 tl">
                    {prettyFloatMoney(loan_amount)}
                  </span>
                </div>
                <div className={s.tbody_col}>
                  <ProgressBar value={repayment_status} />
                </div>
                <div className="loans_table__tbody_col tl">
                  <span className="f-16 f-400">
                    {prettyFloatMoney(repaid_amount)}
                  </span>
                </div>
                <div className={classnames(s.tbody_col)}>
                  <div className={classnames(s.earnings_content, 'f-16 f-400')}>
                    {fundraising_id > 92 ? (
                      <span className={classnames(s.earnings, 'f-16 f-400')}>
                        + {prettyFloatMoney(earnings + flat_fee_accrued)} out of{' '}
                        {prettyFloatMoney(max_earnings)}
                      </span>
                    ) : (
                      <span className={classnames(s.earnings, 'f-16 f-400')}>
                        + {prettyFloatMoney(earnings)}
                      </span>
                    )}

                    {fundraising_id > 92 && (
                      <Tooltip
                        icon={<InfoIcon />}
                        renderContent={() => (
                          <div
                            className="f-16 f-400"
                            style={{ maxWidth: '350px' }}
                          >
                            <span className="f-500">
                              Two Key Values to Track
                            </span>{' '}
                            <br />
                            <br />
                            <span className="f-500">
                              {prettyFloatMoney(earnings + flat_fee_accrued)}:
                            </span>{' '}
                            This is the current amount of the flat fee accrued
                            since the beginning of the 6-month loan period. For
                            Group A it is accrued monthly and for Group B it is
                            accrued in the 6th month. <br />
                            <br />
                            <span className="f-500">
                              {prettyFloatMoney(max_earnings)}:
                            </span>{' '}
                            This shows the total potential income over 6 months
                            if the rules for the increased monthly income of 1%
                            are met for Group A. For Group B, it is a fixed flat
                            fee of 9% for the 6-month period. <br />
                            <br />
                            <span className="f-500">Important Note</span>: If
                            the rules for increased income of 1% in Group A are
                            not met in any actual month, the income for that
                            month will be reduced to 0.75%, making it impossible
                            to reach the maximum value. <br />
                            <br />
                            The scheduled payment date is{' '}
                            <span className="f-500">
                              {prettyDate(end_date, 'DD.MM.YYYY')}
                            </span>
                            . However, this date may change if the repayment
                            period is extended for an additional 6 months by a
                            brand. In this case, you&apos;ll receive an
                            additional flat fee for the extension.
                          </div>
                        )}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);
