import { createBrowserHistory } from 'history';
import originalAxios from 'axios';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import axios from '../axios-api';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import config from '../config';
import { SET_TOKEN } from './actions/actionTypes';
import { logoutUser } from './actions/userActions';
import commonReducer from './reducers/commonReducer';
import dashboardReducer from './reducers/dashboardReducer';
import profileReducer from './reducers/profileReducer';
import loanReducer from './reducers/loanReducer';
import activityReducer from './reducers/activityReducer';
import userReducer, {
  initialState as userStoreInitialState,
} from './reducers/userReducer';
import investReducer, {
  initialState as investStoreInitialState,
} from './reducers/investReducer';
import fundReducer, {
  initialState as fundStoreInitialState,
} from './reducers/fundReducer';
import founderReducer from './reducers/founderReducer';
import investorReducer from './reducers/investorReducer';
import helpReducer from './reducers/helpReducer';
import companyReducer from './reducers/companyReducer';
import referralReducer from './reducers/referralReducer';
import startupReducer from './reducers/startupReducer';
import questionReducer from './reducers/questionReducer';
import complaintReducer from './reducers/complaintReducer';
import roundHistoryReducer from './reducers/roundHistoryReducer';
import bonusReducer from './reducers/bonusReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  userStore: userReducer,
  commonStore: commonReducer,
  dashboardStore: dashboardReducer,
  profileStore: profileReducer,
  fundStore: fundReducer,
  loanStore: loanReducer,
  activityStore: activityReducer,
  investStore: investReducer,
  founderStore: founderReducer,
  startupStore: startupReducer,
  investorStore: investorReducer,
  helpStore: helpReducer,
  companyStore: companyReducer,
  referralStore: referralReducer,
  questionStore: questionReducer,
  complaintStore: complaintReducer,
  roundHistoryStore: roundHistoryReducer,
  bonusStore: bonusReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === `development`) {
  // eslint-disable-next-line global-require
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({
    diff: true,
    collapsed: true,
    titleFormatter: action => `[action] ${action.type}`,
    level: { prevState: false, nextState: false, error: false },
  });
  middleware.push(logger);
}

const enhancers = composeEnhancers(applyMiddleware(...middleware));

let persistedState;
const savedData = loadFromLocalStorage();

if (savedData) {
  persistedState = {
    userStore: {
      ...userStoreInitialState,
      ...savedData.userStore,
    },
    investStore: {
      ...investStoreInitialState,
      ...savedData.investStore,
    },
    fundStore: {
      ...fundStoreInitialState,
      ...savedData.fundStore,
    },
  };
}

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveToLocalStorage({
    userStore: {
      user: store.getState().userStore.user,
      token: store.getState().userStore.token,
    },
  });
});

axios.interceptors.request.use(config => {
  try {
    const token =
      store.getState().userStore.temp_token ||
      store.getState().userStore.token.access_token;
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
  } catch (e) {
    // do nothing, user is not logged in
  }
  return config;
});

let isRefreshingToken = false;

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      const tokenExpireMessage = 'access_token expired';

      if (error.response?.data?.message === tokenExpireMessage) {
        const originalConfig = error.config;
        const { token } = store.getState().userStore;

        // checks the store state, if there isn't any refresh token proccessing attempts to get new token and retry the failed request
        if (!isRefreshingToken) {
          return new Promise(resolve => {
            // updates the state in store so other failed API with 401 error doesnt get to call the refresh token request
            isRefreshingToken = true;

            originalAxios
              .post(config.apiURL + 'token/refresh/', {
                refresh_token: token.refresh_token,
              })
              .then(
                res => {
                  if (res.status === 200) {
                    // We get new access token, trying to re-fetch with new token
                    store.dispatch({
                      type: SET_TOKEN,
                      token: {
                        access_token: res.data.access_token,
                        refresh_token: res.data.refresh_token,
                      },
                    });
                    originalConfig.headers.Authorization = `Bearer ${res.data.access_token}`;
                    return resolve(axios(originalConfig));
                  }
                },
                () => {
                  // Refresh token expired, force logout
                  store.dispatch(logoutUser());
                  window.location.reload();
                }
              )
              .catch(() => {
                store.dispatch(logoutUser());
                window.location.reload();
              })
              .finally(() => {
                isRefreshingToken = false;
              });
          });
        }

        return new Promise(resolve => {
          // in a 100ms time interval checks the store state
          const intervalId = setInterval(() => {
            // if the state indicates that there is no refresh token request anymore, it clears the time interval and retries the failed API call with updated token data
            if (!isRefreshingToken) {
              clearInterval(intervalId);
              resolve(axios(originalConfig));
            }
          }, 100);
        });
      } else {
        store.dispatch(logoutUser());
        window.location.reload();
      }
    }

    return error.response
      ? error.response
      : { data: { message: error.message } };
  }
);

export default store;
