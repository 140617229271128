import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { RoundButton } from '../../UI/RoundButton';
import { DATE_FORMAT_DD_MMM_YYYY } from '../../../helpers/common';
import { prettyFloatMoney } from '../../../helpers/utils';
import { InfoIcon } from '../../UI/Icons';
import { setModals } from '../../../store/actions/commonActions';
import { MODAL_TYPES } from '../../ModalProvider';
import s from './index.module.scss';

export const LoanCard = ({ round, onDetailsClick, className }) => {
  const dispatch = useDispatch();

  const {
    fundraising_name,
    fundraising_id,
    max_earnings,
    start_date,
    flat_fee_accrued,
    loan_amount,
    repayment_status,
    repaid_amount,
    earnings,
  } = round;

  const onTooltipClick = () => {
    dispatch(
      setModals({
        type: MODAL_TYPES.your_earnings,
        round,
      })
    );
  };

  return (
    <div className={classnames(s.card, className)}>
      <div className={s.company}>
        <div className={classnames(s.company_name, 'f-18 f-500')}>
          {fundraising_name}
        </div>
      </div>

      <div className={s.info}>
        <div className={s.row}>
          <div className="c-grey f-14 f-400">Origination date</div>
          <div className="f-18 f-500">
            {moment(start_date).format(DATE_FORMAT_DD_MMM_YYYY)}
          </div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Loan amount</div>
          <div className="f-18 f-500">{prettyFloatMoney(loan_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Repayment progress</div>
          <div className="f-18 f-500">{parseInt(repayment_status)}%</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Repayments</div>
          <div className="f-18 f-500">{prettyFloatMoney(repaid_amount)}</div>
        </div>

        <div className={s.row}>
          <div className="c-grey f-14 f-400">Your earnings</div>
          <div className={classnames(s.earnings_block)}>
            {fundraising_id > 92 ? (
              <span className={classnames(s.earnings, 'f-18 f-500')}>
                + {prettyFloatMoney(earnings + flat_fee_accrued)} out of{' '}
                {prettyFloatMoney(max_earnings)}
              </span>
            ) : (
              <span className={classnames(s.earnings, 'f-18 f-500')}>
                + {prettyFloatMoney(earnings)}
              </span>
            )}

            {fundraising_id > 92 && (
              <InfoIcon onClick={onTooltipClick} style={{ minWidth: '20px' }} />
            )}
          </div>
        </div>
      </div>

      <RoundButton
        label="More details"
        onClick={onDetailsClick}
        className={s.more}
        fullWidth
      />
    </div>
  );
};
