export const REGISTER_USER = {
  REQUEST: 'REGISTER_USER_REQUEST',
  SUCCESS: 'REGISTER_USER_SUCCESS',
  FAILURE: 'REGISTER_USER_FAILURE',
};

export const LOGIN_USER = {
  REQUEST: 'LOGIN_USER_REQUEST',
  SUCCESS: 'LOGIN_USER_SUCCESS',
  FAILURE: 'LOGIN_USER_FAILURE',
};

export const LOGOUT_USER = 'LOGOUT_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

export const SET_USER_LOCATION = 'SET_USER_LOCATION';

export const GET_FOUNDER_INFO = {
  REQUEST: 'GET_FOUNDER_INFO_REQUEST',
  SUCCESS: 'GET_FOUNDER_INFO_SUCCESS',
  FAILURE: 'GET_FOUNDER_INFO_FAILURE',
};

export const SET_INNER_WIDTH = 'SET_INNER_WIDTH';
export const SET_MODALS = 'SET_MODALS';

export const GET_BALANCE = 'GET_BALANCE';
export const GET_FR_DATES = 'GET_FR_DATES';
export const GET_EARNINGS = 'GET_EARNINGS';
export const GET_HAPPY_FOUNDERS = 'GET_HAPPY_FOUNDERS';

export const GET_LOAN_GROUPS = {
  REQUEST: 'GET_LOAN_GROUPS_REQUEST',
  SUCCESS: 'GET_LOAN_GROUPS_SUCCESS',
  FAILURE: 'GET_LOAN_GROUPS_FAILURE',
};
