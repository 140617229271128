import React from 'react';
import { prettyDate, prettyFloatMoney } from '../../helpers/utils';
import s from './index.module.scss';

const YourEarningsModal = ({ round }) => {
  const { max_earnings, flat_fee_accrued, earnings, end_date } = round;

  return (
    <div className={s.wrapper}>
      <div className="f-16 f-400">
        <span className="f-500">Two Key Values to Track</span> <br />
        <br />
        <span className="f-500">
          {prettyFloatMoney(earnings + flat_fee_accrued)}:
        </span>{' '}
        This is the current amount of the flat fee accrued since the beginning
        of the 6-month loan period. For Group A it is accrued monthly and for
        Group B it is accrued in the 6th month. <br />
        <br />
        <span className="f-500">{prettyFloatMoney(max_earnings)}:</span> This
        shows the total potential income over 6 months if the rules for the
        increased monthly income of 1% are met for Group A. For Group B, it is a
        fixed flat fee of 9% for the 6-month period. <br />
        <br />
        <span className="f-500">Important Note</span>: If the rules for
        increased income of 1% in Group A are not met in any actual month, the
        income for that month will be reduced to 0.75%, making it impossible to
        reach the maximum value. <br />
        <br />
        The scheduled payment date is{' '}
        <span className="f-500">{prettyDate(end_date, 'DD.MM.YYYY')}</span>.
        However, this date may change if the repayment period is extended for an
        additional 6 months by a brand. In this case, you&apos;ll receive an
        additional flat fee for the extension.
      </div>
    </div>
  );
};

export default YourEarningsModal;
