import React from 'react';
import classnames from 'classnames';
import {
  Tooltip as FloatTooltip,
  TooltipContent,
  TooltipTrigger,
} from './controls';
import { ReactComponent as IconTooltip } from '../../assets/icons/icon_tooltip_grey_20.svg';
import './index.scss';

export const Tooltip = ({ icon, className, renderContent }) => (
  <FloatTooltip>
    <TooltipTrigger className={classnames('tooltip_trigger', className)}>
      {icon || <IconTooltip />}
    </TooltipTrigger>
    <TooltipContent className="tooltip_content">
      {renderContent()}
    </TooltipContent>
  </FloatTooltip>
);
