import React from 'react';
import classnames from 'classnames';
import { MyLink } from '../../../../../components/MyLink';
import Tooltip from '../../../../../components/Tooltip';
import { LOAN_GROUPS } from '../../../../../helpers/constants';
import s from './index.module.scss';

export const LoanGroupCard = ({ group, className }) => {
  return (
    <div className={classnames(s.card, className)}>
      <MyLink
        href={group.details_link}
        className={classnames(s.card__title, 'hov', 'f-26 f-500')}
      >
        {group.title}
      </MyLink>
      {group.is_active ? (
        <>
          <div className={s.card__block}>
            <div className="f-18 f-500">
              <span>Target annual return</span>
              <Tooltip
                position="bottom center"
                renderContent={() => (
                  <div className={classnames(s.card__tooltip, 'f-16 f-400')}>
                    Target annual return calculations include reinvested
                    repayments. Reinvesting significantly boosts your returns
                    over time.
                  </div>
                )}
              />
            </div>
            <p className="f-18 f-500">{group.investor_annual_rate}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Term</div>
            <p className="f-18 f-500 tl">{group.term}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">
              {group.id === 'conservative' ? (
                <span>Flat fee</span>
              ) : (
                <span>Flat fee for 6 months</span>
              )}
            </div>
            <p style={{ display: 'flex', flexDirection: 'column' }}>
              <span
                className="f-18 f-500"
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                {group.flat_fee}{' '}
                {group.id === LOAN_GROUPS.conservative.id && (
                  <Tooltip
                    position="bottom center"
                    renderContent={() => (
                      <div
                        className={classnames(s.card__tooltip, 'f-16 f-400')}
                      >
                        Basic income - 0.75% per month is accrued on the
                        outstanding loan amount.
                        <br /> <br />
                        Increased income - 1% per month on the outstanding loan
                        amount. Accrued with monthly replenishment and
                        investments not less than €100 per month during the all
                        loan repayment period.
                        <br /> <br />
                        Total return is accrued on a monthly basis but is paid
                        at the end of the loan term.
                      </div>
                    )}
                  />
                )}
              </span>
              {group.id === LOAN_GROUPS.conservative.id && (
                <span className="f-14 f-500">on the outstanding loan</span>
              )}
            </p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Flat fee repayment</div>
            <p className="f-18 f-500 tl">{group.flat_fee_repayment}</p>
          </div>

          <div className={s.card__block}>
            <div className="f-18 f-500">Downside protection</div>
            <p className="f-18 f-500 tl">{group.downside_protection}</p>
          </div>

          <MyLink
            href={group.details_link}
            className={classnames(s.card__link_details, 'f-500')}
          >
            See details
          </MyLink>
        </>
      ) : (
        <p className={classnames(s.card__unavailable, 'f-18 f-500')}>
          {group.title} is temporarily unavailable.
        </p>
      )}
    </div>
  );
};
