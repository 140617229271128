import React, { Component } from 'react';
import { getPrioritySelections } from '../../../store/services/investingServices';
import StartupCard from '../../../components/Cards/StartupCard';
import { WIZARD_REDIRECTS } from '../index';

class PriorityFoundersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 100,
      founders: null,
    };
  }

  componentDidMount() {
    this.getFounders(this.props.setSelections);
  }

  getFounders = setSelections => {
    const { page, limit } = this.state;
    getPrioritySelections({ page, limit }).then(res => {
      if (res && res.success) {
        this.setState(prevState => ({
          ...prevState,
          founders: res.data,
        }));
        setSelections &&
          setSelections(
            res.data.list.filter(founder => founder.is_selected).map(f => f.id)
          );
      }
    });
  };

  onFounderSelect = founder => {
    try {
      const { founders } = this.state;
      if (founders) {
        this.setState(prevState => {
          const updatedList = prevState.founders.list.map(user => {
            if (user.id === founder.id) {
              return {
                ...founder,
                is_selected: !founder.is_selected,
              };
            } else return user;
          });

          this.props.setSelections &&
            this.props.setSelections(
              updatedList.filter(founder => founder.is_selected).map(f => f.id)
            );
          return {
            ...prevState,
            founders: {
              ...prevState.founders,
              list: updatedList,
            },
          };
        });
      }
    } catch (e) {}
  };

  render() {
    const { founders } = this.state;
    const { selectedStrategy } = this.props;
    const isEmpty = !founders || !(founders && founders.total_count);

    return (
      <>
        <h2 className="f-26 f-500">Which founders do you like the most?</h2>
        <p className="priority-founders-list__subtitle f-16 f-400">
          Select founders who will be the first to receive your cash.
        </p>
        {isEmpty ? (
          <div className="priority-founders-list__empty f-16 f-400">
            No brands
          </div>
        ) : (
          <div className="priority-founders-list">
            {founders.list.map(founder => (
              <StartupCard
                key={founder.id}
                founders={founder.co_founders}
                company={founder.company}
                fundraisingStatus={founder.fundraising_status}
                isSelected={founder.is_selected}
                onSelect={() => this.onFounderSelect(founder)}
                founderRedirect={`/founders/${founder.id}?to=${
                  WIZARD_REDIRECTS.strategy
                }${selectedStrategy ? `&strategy=${selectedStrategy}` : ''}`}
                className="priority-founders-list__item"
                active
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default PriorityFoundersList;
